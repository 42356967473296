import { post } from '@/http/http'

// 告警类型数量查询
export const busTypeNum = p => post('v1/businessdeal/alarmtypenum', p)

// 告警单位列表
export const busUnitList = p => post('v1/businessdeal/alarmcompany', p)

// 告警单位详情
export const busUnitInfo = p => post('v1/businessdeal/companydata', p)

// 告警详情列表
export const alarmList = p => post('v1/businessdeal/alarmlist', p)

// 告警详情信息
export const alarmInfo = p => post('v1/businessdeal/alarmbasic', p)

// 电核处理
export const nucleus = p => post('v1/businessdeal/phonedeal', p)

// 核警时长
export const nuclearLong = p => post('v1/businessdeal/nuclearlong', p)

// 核警状态
export const nuclearState = p => post('v1/businessdeal/nuclearstate', p)

// 告警处理
export const busDealWith = p => post('v1/businessdeal/alarmdeal', p)

// 延时处理
export const busDelayedDeal = p => post('v1/businessdeal/alarmdelay', p)

// 处理流程
export const busDealProcess = p => post('v1/businessdeal/dealprocess', p)

// 告警历史处理列表
export const alarmdeaList = p => post('v1/businessdeal/alarmdealist', p)

// 告警处理导出
export const alarmExport = p => post('v1/businessdeal/alarmexport', p)

// 处理结果
export const dealResults = p => post('v1/businessdeal/dealresults', p)

// 故障导出
export const faultExport = p => post('v1/businessdeal/faultexport', p)
