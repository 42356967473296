import { get, put } from '@/http/http'

// 设备总数
export const getEquAllNum = p => get('v1/homepage/homepage_count', p)

// 评分查询
export const getUnitScore = p => get('v1/homepage/homepage_score', p)

// 告警数量
export const getAlarmNum = p => get('v1/homepage/homepage_cnum', p)

// 建筑查询
export const getBuildInfo = p => get('v1/homepage/homepage_build', p)

// 告警信息
export const getAlarmList = p => get('v1/homepage/homepage_table', p)

// 设备信息
export const getEquInfo = p => get('v1/homepage/homepage_equtype', p)

// 设备分布
export const getEquSpot = p => get('v1/homepage/homepage_bution', p)

// 告警基本信息
export const alarmDetails = p => get('v1/homepage/alarmdailes', p)

// 告警设备统计
export const alarmStat = p => get('v1/homepage/parameterstatis', p)

// 历史告警
export const alarmMonth = p => get('v1/homepage/historyalarm', p)

// rabbit通知已读
export const msgRead = p => put('v1/noticeinfo/unoticeread', p)

// rabbit登陆通知
export const msgLogin = p => get('v1/noticeinfo/getnoticesend', p)

// 客服单位分布
export const customerUnit = p => get('v1/customer/companydistr', p)