<!--告警详情-->
<template>
  <div class="AD_content">
    <div class="box100" style="display: flex;">
      <div class="dia_left">
        <div class="homeItemBg flexAC" style="position: relative;">
          <span class="el-icon-caret-right qctIco"></span>
          <span class="qctTitle">基本信息</span>
          <span class="adTime">{{ info.ectptime }}</span>
          <p class="adFonf">
            <span class="lx" v-if="info.equcfonf === 1">离线</span>
            <span class="zx" v-if="info.equcfonf === 2">在线</span>
          </p>
        </div>
        <div class="jb_cont">
          <div class="jbL">
            <p class="jbpb">单位名称: {{ info.companyname }}</p>
            <p class="jbpb">设备地址: {{ info.adderss }}</p>
            <p class="jbpb">告警内容: {{ info.ectpmark }}</p>
            <p v-if="isCustomer">核警时长: {{ info.alatime }}</p>
          </div>
          <div class="jbM">
            <p class="jbpb">设备类型: {{ info.equname }}</p>
            <p class="jbpb">是否逾期:
              <span v-if="info.ectpwttl === '1'" class="yqspan">否</span>
              <span v-if="info.ectpwttl === '2'" class="yqspan">是</span>
            </p>
            <p class="jbpb">安全管理人: {{ info.username }}</p>
            <p v-if="isCustomer">核警状态: {{ info.statename }}</p>
          </div>
          <div class="jbR">
            <p class="jbpb">设备编号: {{ info.equimei }}</p>
            <p class="jbpb">告警类型: {{ info.ectptypename }}</p>
            <p>联系电话: {{ info.userphone }}</p>
          </div>
        </div>
        <div class="homeItemBg flexAC">
          <span class="el-icon-caret-right qctIco"></span>
          <span class="qctTitle">参数统计</span>
        </div>
        <div class="tj_cont">
          <div ref="tjEchart" style="width: 100%;height:100%;"></div>
        </div>
        <div class="homeItemBg flexAC"  v-if="info.equtype === '20'">
          <span class="el-icon-caret-right qctIco"></span>
          <span class="qctTitle">抓拍图片</span>
        </div>
        <div class="listItem" v-if="info.equtype === '20'">
          <div class="itemBox" >
            <img :src="alaimg" alt @click="openImgHandle(alaimg)"  :onerror="notImg"/>
          </div>
        </div>
        <div class="handBtnBox flexC" v-if="info.ectpisok === '1'">
          <!-- 单位告警操作  -->
          <div v-if="!isCustomer" class="flexC">
            <template v-if="info.ectptype !== '1'">
  <!--            <div class="handbtn" @click="handDeal">处理</div>-->
              <!-- 用户-(故障-隐患)-分派 督办 -->
              <template v-if="info.ectptype === '2' || info.ectptype === '3'">
                <div class="handbtn" @click="handAssign">分派</div>
                <div class="handbtn" v-if="info.ectpdisp === '2'" @click="handSupervise">督办</div>
              </template>
              <!-- 用户-(故障)误报 -->
              <template v-if="info.ectptype === '2'">
                <div class="handbtn" @click="handMisreport">误报</div>
              </template>
              <!-- 用户-(隐患)处理-延时处理 -->
              <template v-if="info.ectptype === '3'">
                <div class="handbtn" @click="customerDeal">处理</div>
                <div class="handbtn" v-if="info.delaybutt === true" @click="customerYsDeal">延时处理</div>
              </template>
              <!-- 用户-(故障-其他)处理 -->
              <template v-if="info.ectptype === '2' || info.ectptype === '4'">
                <div class="handbtn" @click="gzDeal('1','qt')">处理</div>
              </template>
            </template>
          </div>
          <!-- 客服告警操作  -->
          <div v-if="isCustomer" class="flexC">
            <template v-if="info.ectptype === '1' || info.ectptype === '3'">
              <div class="handbtn btnLs" v-if="info.button" @click="customerCall">电核</div>
            </template>
            <div class="handbtn" v-if="info.ectptype === '1' || info.ectptype === '3'" @click="customerDeal">处理</div>
            <div class="handbtn" v-if="info.ectptype === '3' && info.delaybutt === true" @click="customerYsDeal">延时处理</div>
            <div class="handbtn" v-if="info.ectptype === '2'" @click="gzDeal('5','gz')">项目处理</div>
            <div class="handbtn btnLs" v-if="info.ectptype === '2'" @click="gzDeal('1','gz')">处理</div>
            <div class="handbtn" v-if="info.ectptype === '4'" @click="gzDeal('1','qt')">处理</div>
          </div>
        </div>
      </div>
      <div class="dia_right">
        <el-steps direction="vertical" class="dia_step">
          <el-step class="diaStep_item" v-for="item in processList" :key="item.index">
            <template slot="description">
              <p class="stepTime">{{ item.altime }}</p>
              <p>{{ item.username }}</p>
              <p>{{ item.atitle }}</p>
              <p>{{ item.almark }}</p>
              <!-- <p>{{ item.almark }}</p> -->
              <p>{{ item.aldelay }}</p>
              <img v-if="item.alfile !== ''" :src="item.alfile" style="width: 80px;height: 80px;" @click="showBig(item.alfile)"/>
            </template>
          </el-step>
        </el-steps>
      </div>
    </div>

    <el-dialog append-to-body title="预览" :visible.sync="showImgDia" center width="800px" class="hdBg hdTb" style="z-index: 999;">
      <div class="">
        <div style="width: 100%;height: auto;">
          <img :src="showImgUrl" alt="" style="width: 100%"/>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { alarmDetails, alarmStat } from '@/http/api/home'
import { aigetImg } from '@/http/api/unit'
import { alarmInfo, busDealProcess, nucleus } from '@/http/api/business'
import { busDealWith } from '@/http/api/business'
var echarts = require('echarts')
export default {
  props: ['alarmKey'],
  data () {
    return {
      info: '',
      xArr: [],
      xBrr1: [],
      xBrr2: [],
      xBrr3: [],
      imgList: [],
      alaimg: '',
      notImg:  'this.src="'+ require('../../assets/img/imgError.png') +'"',
      isCustomer: false,
      processList: [],
      showImgUrl: '',
      showImgDia: false
    }
  },
  watch: {
    alarmKey (val) {
      this.xArr = []
      this.xBrr1 = []
      this.xBrr2 = []
      this.xBrr3 = []
      this.getAlarmStats()
      this.init()
    }
  },
  mounted () {
    let roleId = JSON.parse(window.localStorage.getItem('loginInfo')).roleid
    if (roleId === '12') { this.isCustomer = true }
    else { this.isCustomer = false }
    this.getAlarmStats()
  },
  created () {
    this.init()
  },
  methods: {
    // 基本信息
    init () {
      // alarmDetails({ ectpid: this.alarmKey.ectpid }).then(res => {
      //   if (res.status === '1') {
      //     this.info = res.data
      //   }
      // })
      /* 告警类型 */
      alarmInfo({ ectpid: this.alarmKey.ectpid }).then(res => {
        if (res.status === '1') {
          this.info = res.data
        }
      })

      // 抓拍图片
      const params = {
        equid: this.alarmKey.equid,
        alatime: this.alarmKey.ectptime
      }
      aigetImg(params).then(res => {
        if (res.status === '1') {
          this.alaimg = res.data.alaimg
        } else {
          this.alaimg = ''
        }
      })

      /* 处理流程 */
      busDealProcess({ ectpid: this.alarmKey.ectpid }).then(res => {
        if (res.status === '1') {
          this.processList = res.data
        }
      })
    },
    // 参数统计
    getAlarmStats () {
      alarmStat({ equid: this.alarmKey.equid }).then(res => {
        if (res.status === '1') {
          if (res.data.length > 0) {
            for (var i = 0; i < res.data.length; i++) {
              this.xArr.push(res.data[i].accordtime)
              this.xBrr1.push(res.data[i].alarm)
              this.xBrr2.push(res.data[i].fault)
              this.xBrr3.push(res.data[i].tives)
            }
            this.getTjEchart()
          } else {
            this.xArr = []
            this.xBrr1 = []
            this.xBrr2 = []
            this.xBrr3 = []
          }
        } else {
          this.xArr = []
          this.xBrr1 = []
          this.xBrr2 = []
          this.xBrr3 = []
        }
      })
    },
    getTjEchart () {
      var echart = echarts.init(this.$refs.tjEchart)
      var max = Math.max(...this.xBrr1)
      if (max > 5) {
        max = Math.max(...this.xBrr1)
      } else {
        max = 5
      }
      var option = {
        color: ['#FF6242', '#4295FF', '#33D162'],
        grid: {
          top: '18%',
          bottom: '0%',
          left: '1%',
          right: '3%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(136, 172, 252, 0.16)',
          borderColor: 'rgba(136, 172, 252, 0.16)',
          textStyle: { color: '#FFFFFF' }
        },
        legend: {
          right: 0,
          textStyle: { color: '#A5B7CC' },
          data: ['告警趋势', '故障趋势', '误报趋势']
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisLine: { show: false },
          axisTick: { show: false },
          data: this.xArr
        },
        yAxis: {
          type: 'value',
          max: max,
          splitLine: {
            lineStyle: {
              color: '#eee',
              width: 0.5,
              type: [2, 10],
              dashOffset: 2
            }
          }
        },
        series: [
          {
            name: '告警趋势',
            type: 'line',
            symbol: 'none',
            smooth: true,
            data: this.xBrr1,
            areaStyle: { opacity: 0.3 }
          },
          {
            name: '故障趋势',
            type: 'line',
            symbol: 'none',
            smooth: true,
            data: this.xBrr2,
            areaStyle: { opacity: 0.3 }
          },
          {
            name: '误报趋势',
            type: 'line',
            symbol: 'none',
            smooth: true,
            data: this.xBrr3,
            areaStyle: { opacity: 0.3 }
          }
        ]
      }
      echart.setOption(option)
    },
    // 处理
    handDeal () {
      this.$emit('dealHandShow', this.info)
    },
    // 派单
    handAssign () {
      this.$emit('assignHandShow', this.info)
    },
    // 督办
    handSupervise () {
      this.$emit('superviseHandShow', this.info)
    },
    // 误报
    handMisreport () {
      // this.$emit('misreportHandShow', this.info)
      this.$confirm('点击误报后本条告警信息将会自动标记为误报信息，请确定是否误报?', '误报', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'Misinform',
        center: true
      }).then(() => {
        const params = {
          ectpid: this.info.ectpid,
          dealtype: '2',
          dealmark: '',
          tgmark: '',
          dealfile: ''
        }
        busDealWith(params).then(res => {
          if (res.status === '1') {
            this.$emit('misreportHandShow')
            this.$message.success(res.message)
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    openImgHandle (res) {
      this.$emit('openImg', res)
    },
    /* 客服电核 */
    customerCall () {
      nucleus({ ectpid: this.info.ectpid }).then(res => {
        if (res.status === '1') {
          this.$emit('customerTel', this.info)
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    /* 客服-处理(火警-隐患) */
    customerDeal () {
      this.$emit('customerDealShow', this.info)
    },
    /* 隐患-延时处理 */
    customerYsDeal () {
      this.$emit('ysDealShow', this.info)
    },
    /* 故障(项目处理-已处理) -其他(处理) */
    gzDeal (type, cla) {
      let tip = ''
      if (cla === 'gz') {
        if (type === '5') { tip = '请确认交接项目处理，详情查看故障导出'}
        else{ tip = '请确认处理该故障' }
      } else {
        tip = '请确认处理该告警'
      }
      this.$confirm(tip, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'Misinform',
        center: true
      }).then(() => {
        const params = {
          ectpid: this.info.ectpid,
          dealtype: type,
          dealmark: '',
          tgmark: '',
          dealfile: ''
        }
        busDealWith(params).then(res => {
          if (res.status === '1') {
            this.$emit('busGzDeal', this.info)
            this.$message.success(res.message)
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    showBig (url) {
      this.showImgDia = true
      this.showImgUrl = url
    }
  }
}
</script>

<style scoped>
.AD_content{
  width: 100%;
  min-height: 510px;
  position: relative;
  color: #fff;
}
.dia_left{
  width: 75%;
  height: 100%;
  padding-right: 25px;
  box-sizing: border-box;
  border-right: 1px solid #1D385B;
}
.jb_cont{
  width: 100%;
  padding: 20px 0px;
  box-sizing: border-box;
  color: #fff;
  font-size: 14px;
  display: flex;
}
.jbL{
  width: 45%;
}
.jbM{
  width: 25%;
}
.jbR{
  width: 30%;
}
.jbpb{
  margin-bottom: 15px;
}
.adTime{
  color: #FFAD42;
  font-size: 16px;
  margin-left: 30px;
}
.adFonf{
  position: absolute;
  right: 0px;
}
.adFonf .zx{
  width: 50px;
  height: 30px;
  display: block;
  text-align: center;
  background: rgba(136, 172, 252, 0.16);
  color: #22F298;
  border-radius: 4px;
}
.adFonf .lx{
  width: 50px;
  height: 30px;
  display: block;
  text-align: center;
  background: rgba(245, 34, 45, 0.16);
  color: #F5222D;
  border-radius: 4px;
}
.tj_cont{
  width: 100%;
  height: 220px;
  padding: 20px 0px;
  box-sizing: border-box;
}
.listItem{
  width: 20%;
  padding-top: 20px;
}
.itemBox{
  box-sizing: border-box;
  margin-right: 20px;
  margin-bottom: 30px;
  position: relative;
  border-radius: 4px;
}
.itemBox img{
  width: 100%;
  height: 160px;
}
.yqspan{
  color: #42EEFF;
}
.handBtnBox{
  width: 100%;
  margin-top: 30px;
  position: relative;
}
.handbtn{
  width: 110px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin: 0px 30px;
  background: rgba(136, 172, 252, 0.16);
  border-radius: 4px;
  color: #FFAD42;
  cursor: pointer;
}
.btnLs{
  color: #1FDB8A;
}
.dia_right{
  width: 23%;
  min-height: 510px;
  max-height: 520px;
  padding-left: 25px;
  box-sizing: border-box;
  overflow-y: scroll;
  scrollbar-width: none;  /*火狐兼容*/
  color: #fff;
}
.dia_right::-webkit-scrollbar {
  width: 0px; /* 谷歌兼容 */
}
.stepTime{
  color: #A5B7CC;
  font-size: 12px;
}
.el-steps--vertical{
  height: auto;
}
.dia_step >>> .el-step__icon-inner{
  display: none;
}
.diaStep_item{
  font-size: 14px;
}
.dia_step >>> .el-step__icon.is-text{
  width: 14px;
  height: 14px;
  border: 1px solid #fff;
  background: #3476F3;
}
.dia_step >>> .el-step__line{
  width: 1px;
  left: 7px;
  background: #1D385B;
}
.dia_step >>> .el-step__title{padding: 0px;}
.dia_step >>> .el-step__main{margin-bottom: 15px;}
.dia_step >>> .el-step__description.is-wait{color: #fff;}
</style>
