<template>
<!-- 火警告警-隐患告警-处理表单 -->
  <div class="dealContent">
    <el-form :model="form" :rules="formRules" ref="form" class="alarmHForm">
      <el-form-item label="处理类型" prop="dealtype" class="afItem">
        <el-radio-group v-model="form.dealtype" style="width:550px;" class="alarmRadio">
          <el-radio v-for="(item,index) in typeOption" :key="index" :label="item.value">{{ item.name }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="form.dealtype === '6'" label="技术协助" prop="tgmark" class="afItem">
        <el-select v-model="form.tgmark" placeholder="请选择协助原因" class="afInput">
          <el-option
            v-for="item in reason"
            :key="item"
            :label="item"
            :value="item">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="处理内容" prop="dealmark" class="afItem" style="margin-bottom: 0px;">
        <textarea v-model="form.dealmark" class="OperTextareas" placeholder="请输入处理内容" maxlength="150"></textarea>
        <p class="OperText"><span>{{ form.dealmark.length }}</span>/150</p>
      </el-form-item>
      <el-form-item label="处理附件" class="afItem afImg" style="margin-bottom: 0px;">
        <Upload :fileType="'jpg/png/jpeg'" class="upImg" @uploadSuccess="ImgSuccess"></Upload>
        <span class="uptip">只支持.jpg.png.jpeg 格式</span>
      </el-form-item>
      <div class="form_tip" v-if="form.dealtype === '6'" style="margin: 10px 0px;">
        <el-radio-group v-model="xzMoney" style="width:550px;" class="alarmRadio">
          <el-radio label="1">上门费+维修费(100元+根据现场状况报价)</el-radio>
        </el-radio-group>
      </div>
      <div class="flexCE">
        <div class="afCancel" @click="dealCancel">取消</div>
        <div class="afConfirm" @click="dealConfirm">确定</div>
      </div>
    </el-form>
  </div>
</template>

<script>
import Upload from '@/components/upload.vue'
import { busDealWith } from '@/http/api/business'
export default {
  name: 'dealWith',
  components: {
    Upload
  },
  props: ['alarmInfo'],
  data () {
    return {
      form: {
        dealtype: '1',
        dealmark: '',
        tgmark: '',
        dealfile: ''
      },
      formRules: {
        dealtype: [{ required: true, trigger: 'blur', message: '请选择处理类型' }],
        dealmark: [{ required: true, trigger: 'blur', message: '处理内容不能为空' }],
        tgmark: [{ required: true, trigger: 'blur', message: '请选择技术协助原因' }]
      },
      /* 客服-(火警)处理 */
      hjType: [
        { name: '误报', value: '2' }, { name: '真实火警', value: '3' },
        { name: '火警', value: '1' }, { name: '拒绝配合', value: '4' }
      ],
      /* 客服-(隐患)处理 */
      yhType: [
        { name: '隐患', value: '1' }, { name: '误报', value: '2' },
        { name: '拒绝配合', value: '4' }, { name: '技术协助', value: '6' }
      ],
      /* 单位用户-(隐患)处理 */
      usType: [
        { name: '隐患', value: '1' }, { name: '误报', value: '2' },{ name: '技术协助', value: '6' }
      ],
      reason: ['未找到原因，无法处理','找到原因，无法处理'],
      typeOption: [],
      btnDisabled: false,
      xzMoney: '1'
    }
  },
  watch: {
    alarmInfo(val) {
      this.alarmInfo = val
      let roleId = JSON.parse(window.localStorage.getItem('loginInfo')).roleid
      if (roleId === '12') {
        if (this.alarmInfo.ectptype === '1') {
          this.typeOption = this.hjType
          this.form.dealtype = '2'
        } else if (this.alarmInfo.ectptype === '3') {
          this.typeOption = this.yhType
          this.form.dealtype = '1'
        }
      } else {
        this.typeOption = this.usType
        this.form.dealtype = '1'
      }
    }
  },
  mounted () {
    let roleId = JSON.parse(window.localStorage.getItem('loginInfo')).roleid
    if (roleId === '12') {
      if (this.alarmInfo.ectptype === '1') {
        this.typeOption = this.hjType
        this.form.dealtype = '2'
      }else if (this.alarmInfo.ectptype === '3') {
        this.typeOption = this.yhType
        this.form.dealtype = '1'
      }
    } else {
      this.typeOption = this.usType
      this.form.dealtype = '1'
    }
    // console.log(this.alarmInfo)
  },
  methods: {
    dealCancel() {
      this.$refs.form.resetFields()
      this.$emit('dealClose')
    },
    ImgSuccess (res) {
      this.form.dealfile = res.filepath
    },
    /* 提交 */
    dealConfirm () {
      this.$refs.form.validate((valid) => {
        if (!valid) return
        const params = {
          ectpid: this.alarmInfo.ectpid,
          dealtype: this.form.dealtype,
          dealmark: this.form.dealmark,
          tgmark: this.form.tgmark,
          dealfile: this.form.dealfile
        }
        busDealWith(params).then(res => {
          if (res.status === '1') {
            this.$emit('busDealSuccess')
            this.$message.success(res.message)
            this.$refs.form.resetFields()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    }
  }
}
</script>

<style scoped>
.alarmRadio >>>.el-radio{
  color: #fff;
}
.upImg{
  width: 500px;
  height: 100px;
  padding-top: 24px;
}
.upImg>>>.uploader{
  background: rgba(136, 172, 252, 0.16);
}
.uptip{
  color: #A5B7CC;
  font-size: 13px;
}
</style>
