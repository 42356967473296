<template>
<!-- 隐患告警-延时处理表单 -->
  <div class="delayedContent">
    <el-form :model="form" :rules="formRules" ref="form" class="alarmHForm">
      <el-form-item label="延时时间" prop="delaytime" class="afItem">
        <el-date-picker
          class="afInput"
          v-model="form.delaytime"
          type="datetime"
          :picker-options = "option"
          placeholder="选择时间"
          >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="备注" prop="almark" class="afItem">
        <textarea v-model="form.almark" class="OperTextareas" placeholder="请输入处理内容" maxlength="150"></textarea>
        <p class="OperText"><span>{{ form.almark.length }}</span>/150</p>
      </el-form-item>
      <el-form-item label="附件" class="afItem afImg">
        <Upload :fileType="'jpg/png/jpeg'" class="upImg" @uploadSuccess="ImgSuccess"></Upload>
        <span class="uptip">只支持.jpg.png.jpeg 格式</span>
      </el-form-item>
      <div class="flexCE">
        <div class="afCancel" @click="dealCancel">取消</div>
        <div class="afConfirm" @click="dealConfirm">确定</div>
      </div>
    </el-form>
  </div>
</template>

<script>
import Upload from '@/components/upload.vue'
import { getNowTime } from '@/utils/validate'
import { busDelayedDeal } from '@/http/api/business'
export default {
  name: 'delayed',
  props: ['alarmInfo'],
  components: {
    Upload
  },
  data () {
    return {
      form: {
        ectpid: '',
        delaytime: '',
        alfile: '',
        almark: ''
      },
      formRules: {
        delaytime: [{ required: true, trigger: 'blur', message: '请选择延时时间' }],
      },
      option : {
        disabledDate : (time) =>  {
          return  time. getTime ()  <   Date . now () - 1   *   24   *   3600   *   1000
        }
      },
    }
  },
  mounted () {

  },
  methods: {
    ImgSuccess (res) {
      this.form.alfile = res.filepath
    },
    dealCancel () {
      this.$refs.form.resetFields()
      this.$emit('delayedClose')
    },
    /* 延时处理-提交 */
    dealConfirm () {
      this.$refs.form.validate((valid) => {
        if (!valid) return
        this.form.delaytime = getNowTime(this.form.delaytime)
        const params = {
          ectpid: this.alarmInfo.ectpid,
          delaytime: this.form.delaytime,
          alfile: this.form.alfile,
          almark: this.form.almark
        }
        busDelayedDeal(params).then(res =>{
          if (res.status === '1') {
            this.$emit('delayedSuccess')
            this.$message.success(res.message)
            this.$refs.form.resetFields()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    }
  }
}
</script>

<style scoped>
.upImg{
  width: 500px;
  height: 100px;
  padding-top: 24px;
}
.upImg>>>.uploader{
  background: rgba(136, 172, 252, 0.16);
}
</style>
